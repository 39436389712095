const root: any = document.getElementById("landing-palette-root");
const project = root.getAttribute("project") || "ped";
const dataEnvironment = root.getAttribute("environment") || "dev-localhost";
const dataChannel = root.getAttribute("channel") || "smeg-italy-it";
const CONFIG = JSON.parse(process.env.REACT_APP_CONFIG || "");


const default_family = [
                    "HBF",
                    "BLENDERS",
                    "SDA_COFFEE_MACHINES",
                    "MILK_FROTHER",
                    "TOASTER",
                    "STAND_MIXER",
                    "COFFEE_GRINDER",
                    "KETTLES",                    
                    "SLOW_JUICERS",
                    "CIT_JUIC",
                    "PBF",
                    "Hand_Mixer"
                ]


export const ATTRIBUTES = CONFIG[dataEnvironment][dataChannel][project]?.query_terms_values || default_family;
export const RETAILERS = CONFIG[dataEnvironment][dataChannel]?.retailers || "";


export const FILTER_BY = CONFIG["settings"]["project"][project].query_filter;
export const FILTER_DOCUMENT_PATH = CONFIG["settings"]["project"][project].filter_documents_path;

export const BRX_LOCALE = CONFIG[dataEnvironment][dataChannel]["locale"];
export const BRX_API_HOST = CONFIG[dataEnvironment][dataChannel]["apiHost"];
export const BRX_HOST = CONFIG[dataEnvironment][dataChannel]["host"];

export const LABELS_URL = BRX_API_HOST + "/v1/label/" + BRX_LOCALE + "?bundles=landing.palette.global";

export const getShortDescription = (originalDescription: string, family: string, sku_model:string): string => {
	
        let newDescription = "";
        let arrayDescription = originalDescription.split(",");
        if(arrayDescription.length > 1){
            newDescription = arrayDescription[0] + " " + arrayDescription[1];
        	if(family == "KETTLES" && arrayDescription.length > 2 ){        	
            	newDescription = arrayDescription[0] + "," + arrayDescription[1] + "," + arrayDescription[2];            	
        	}
        }else{
            newDescription = originalDescription;
        };
        return newDescription;
 }