import React from "react";
import { getShortDescription, FILTER_DOCUMENT_PATH, FILTER_BY, ATTRIBUTES, BRX_API_HOST, BRX_HOST } from "./APIUtils";
import { LabelContext, LabelContextComponent } from "./label.context";

export const GeneralContext = React.createContext<any>(null!);

export const GeneralContextComponent = (props: any) => {


    const { t } = React.useContext(LabelContext);

    const allFamilyObject = { 
        name: t('familyAll'),  
        value: "all", 
        category_code: "colazione",
        disabledImage: BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/family/view-all.png", 
        activeImage: BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/family/view-all.png" ,
        backgroundColor: '#FFFFFF'
    }
    const allColorObject = { 
        name: t('colorAll'),  
        value: "all", 
        disabledImage: BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/colors/rectangular/all_rectangular.png?xs=1", 
        activeImage: BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/family/view-all.png"
    }

    /* === DATI === */
    
    // New version
    const [colors, setColors]       = React.useState([]);
    const [families, setFamilies]   = React.useState([]);
    const [products, setProducts]   = React.useState([]);
    const [models, setModels]       = React.useState([]);
    const [isMobile, setIsMobile]       = React.useState(true);
    
    
    const [changeUrl, setChangeUrl]       = React.useState(false);

    const [start, setStart] = React.useState(0);
    const [selectedColor, setSelectedColor] = React.useState<any>(null!);
    const [selectedFamily, setSelectedFamily] = React.useState<any>(null!);
    const [selectedSku, setSelectedSku] = React.useState();
    const [selectedRetailer, setSelectedRetailer] = React.useState();

    // commenta

    //const [activeFilters, setActiveFilters] = React.useState({ "colors": [], "family": [] });
    const [selectedFilters, setSelectedFilters] = React.useState({ "colors": [], "family": [] });
    const [startState, setStartState] = React.useState(true);

    //const [filterColor, setFilterColor] = React.useState<any>([]);
    //const [filterAttribute, setFilterAttribute] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(1);

    //const [recalculatedListing, setRecalculatedListing] = React.useState([]);
    //const [filterChange, setFilterChange] = React.useState(0);

    const mappingColorsOverride = [
        { "1001": { "code": ["TSF01UJEU"] } },
        { "1002": { "code": ["TSF01CHMEU","KLF03CHMEU"] } },
        { "1003": { "code": ["TSF01WHMEU","KLF03WHMEU"] } },
        { "1004": { "code": ["TSF01BLMEU","KLF03BLMEU"] } },
        { "1005": { "code": ["CKFC2411RDM","CKFC2611RDM","CKFD2811RDM","CKFF2401RDM","CKFF2601RDM","CKFF2801RDM","CKFF3001RDM","CKFS2011RDM","CKFW3001RDM","BCC01RDMEU","BCC02RDMEU"] } }
    ]


    const productsToSkip = ["SMF23BLIT","SMF33CRIT","SMF43RDIT"]

    const handleBack = () =>{
        if(selectedSku){
            setSelectedSku(null!)
        }else{            
            if(selectedColor){
                setSelectedColor(null!);
            }else{
                if(selectedFamily){
                    setSelectedFamily(null!);
                }else{
                    setStart(0)
                }
            }
        }
    }

    const setHashUrl = () => {
        
        let hash = "?"
        if(isMobile){
            if(selectedFamily){            
                hash+="category=" + selectedFamily.value
            }
            if(selectedColor){
                hash+="&color=" + selectedColor.value
            }
            if(selectedSku){
                hash="?sku=" + selectedSku
            }   
            if(selectedRetailer){
                if(hash=="?"){
                    hash="?retailer=" + selectedRetailer
                }else{
                    hash+="&retailer=" + selectedRetailer
                }
            }
        }
        window.history.pushState("", "", hash);
        setChangeUrl(!changeUrl)
    }


    React.useEffect(() => {

        setHashUrl();
    }, [selectedFamily, selectedColor, selectedSku]);


    
    /** 
        Richiama Prodotti e Filtri
        Rielabora i prodotti per semplificare la lettura successiva dei dati
        Estrae i modelli
    **/
    const init = () => {
        const requestOptionsForProduct = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    query_filter: FILTER_BY,
                    query_terms_values: ATTRIBUTES
                }
            )
        }

        const requestOptionsForFilter = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    "filter_documents_path": FILTER_DOCUMENT_PATH
                }
            )
        }

        let _colors:any;

        fetch(BRX_API_HOST + "/v1/beans/retrieve_filters", requestOptionsForFilter)
            .then(res => res.json())
            .then((result) => {
                if (result.code === 200) {
                    const attributes = JSON.parse(result.attributes);
                    _colors = JSON.parse(result.colors);
                    setColors(_colors)
                    
                    
                    let _attributes = attributes.map(
                        (attribute:any) => {
                            return { ...attribute,"activeImage": attribute.activeImage?.replace('/brx%3AdoubleDeskLarge','') ,  "category_code": attribute.category.toLowerCase().replace(" ","-").replace("è","e") };
                            
                        }
                    );
                    
                    setFamilies(_attributes)                    
                    
                    //setFilterAttribute(attributes);
                } else {
                    console.log(result.message)
                }
            },
                (error) => {
                    console.log(error);
                })
            .then(
                () => fetch(BRX_API_HOST + "/v1/beans/products", requestOptionsForProduct)
                    .then(res => res.json())
                    .then((result) => {
                        if (result.code === 200) {
                            
                            const newData = JSON.parse(result.products).filter( (obj:any) => {return !productsToSkip.includes(obj.sku) }  ).map((obj: { mainImage: any; description: any; attribute: any; color: any; sku: any; basicInfo: any, images: any}) => {
                                let model = obj.sku.match(/([^\d]+)([\d]+).*/i);

                                
                                    

                                if(obj.sku.indexOf("DCF02")>-1){
                                    obj.attribute = "20001"
                                }

                                mappingColorsOverride.map( (json_color:any) => {                                        
                                        let id_color = Object.keys(json_color)[0]
                                        if( json_color[id_color].code.includes(obj.sku) ){
                                            obj.color = id_color
                                        }
                                    }
                                )
                                let model_code = ""
                                if(model)
                                    model_code = model[1]+model[2]
                                return {
                                    "image": obj.mainImage ? obj.mainImage : "https://www.smeg.com/webfiles/latest/images/product_placeholder.png",
                                    "description": obj.description,
                                    "attribute": obj.attribute,
                                    "family": obj.attribute,
                                    "color": obj.color,
                                    "sku": obj.sku,
                                    "model": model_code,
                                    "basicInfo": JSON.parse(obj.basicInfo),
                                    "images": obj.images
                                }

                            })
                            
                            setProducts(newData);
                            /* Modelli */
                            
                            let newModels = newData.reduce( 
                                (models: any, currentProduct: any) => { 
                                    if(!models) models = [];
                                    
                                    if(!models.find( (model:any) => (model.model==currentProduct.model) )){
                                        let newModel = { model: currentProduct.model, family:currentProduct.attribute, attribute: currentProduct.attribute, category: currentProduct.category, products:[], description: currentProduct.description }
                                        
                                        newModel.description = getShortDescription(currentProduct.description, currentProduct.category, currentProduct.model);
                                        let tmp_desc = t(currentProduct.model)
                                        if(tmp_desc.indexOf("???") == -1)
                                            newModel.description = tmp_desc

                                        newModel.products = newData.filter( (product:any) => (product.model == currentProduct.model )).map(
                                                (product:any) => {
                                                    let productWithColors = { ...product, colorImage:"", colorName:""}
                                                    productWithColors.colorName = _colors.find((color:any) => (color.value==productWithColors.color))?.name
                                                    productWithColors.colorImage = _colors.find((color:any) => (color.value==productWithColors.color))?.activeImage?.replace('/brx%3AdoubleDeskLarge','')
                                                    productWithColors.backgroundColor = _colors.find((color:any) => (color.value==productWithColors.color))?.backgroundColor
                                                    return productWithColors
                                                }
                                            )

                                        models.push(newModel) 
                                    }
                                    return models;
                                }, 
                                []);     

                            setModels(newModels);          

                            
                        } else {
                            console.log(result.message)
                        }
                    },
                        (error) => {
                            console.log(error);
                        })
                    .then(() => setLoading(0))
            )
    }

    React.useEffect(() => {
        init();
    }, []);


    // ..

    return (
        <GeneralContext.Provider value={{
            colors,      
            families,      
            //filterAttribute,
            //setFilterAttribute,
            products,
            loading,
            selectedFilters,
            setSelectedFilters,
            startState,
            selectedFamily,
            setSelectedFamily,
            setSelectedRetailer,
            selectedColor,
            setSelectedColor,            
            models,            
            start,
            setStart,
            handleBack,
            selectedSku, 
            setSelectedSku,
            allFamilyObject,
            allColorObject,
            changeUrl,
            isMobile, 
            setIsMobile

        }}>
            {props.children}
        </GeneralContext.Provider>
    );
};
